// JavaScript Document
$(function () {
	"use strict";
	var obj = {
		init: function () {
			this.toTop();
			// this.smoothScroll();
			this.iconMenu();
			this.fixed();
		},
		//totop
		fixed: function () {
			const head = $("header").height();
			$(window).on("scroll load", function () {
				if ($(this).scrollTop() > head) {
					$("header,#mainvisual").addClass("active");
				} else {
					$("header,#mainvisual").removeClass("active");
				}
			});
		},
		toTop: function () {
			$("#totop").hide();
			$(window).on("scroll", function () {
				if ($(this).scrollTop() > 100) {
					$("#totop").fadeIn();
					$(".actionTo").addClass("active");
				} else {
					$("#totop").fadeOut();
					$(".actionTo").removeClass("active");
				}
			});
			$("#totop a").click(function () {
				$("body,html").animate(
					{
						scrollTop: 0,
					},
					500
				);
				return false;
			});
		},
		//smoothScroll
		smoothScroll: function () {
			$('a[href^="#"]').click(function () {
				if ($($(this).attr("href")).length) {
					var p = $($(this).attr("href")).offset();
					if ($(window).width() > 640) {
						$("html,body").animate(
							{
								scrollTop: p.top - 170,
							},
							600
						);
					} else {
						$("html,body").animate(
							{
								scrollTop: p.top - 100,
							},
							600
						);
					}
				}
				return false;
			});
			$(window).load(function () {
				var hash1 = location.hash;
				var $root = $("html, body");
				if (hash1 !== "") {
					var top01 = $(hash1).offset().top;
					if ($(window).width() > 640) {
						$root.animate(
							{
								scrollTop: top01 - 170,
							},
							600
						);
					} else {
						$root.animate(
							{
								scrollTop: top01 - 100,
							},
							600
						);
					}
				}
			});
		},

		//sp gnavi
		iconMenu: function () {
			$(".hamburger").on("click", function () {
				$(this).toggleClass("active");
			});

			$("#gnavi .has > label").click(function () {
				$(this).toggleClass("active");
				$(this).next(".sub").slideToggle();
			});

			$(window).bind("load resize", function () {
				var w = $(window).width();
				$(".has > label").removeClass("active");

				if (w > 640) {
					$(".sub").removeAttr("style");
					$("#gnavi .has > label").removeClass("active");
					$(".icon_menu").removeClass("active");
					$("#gnavi").removeAttr("style");
				} else {
					$("#gnavi .has > label").removeClass("active");
				}
			});
		},
	};

	obj.init();
});
